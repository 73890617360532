

import axios from 'axios';
import { AUTH_TOKEN } from './constants';

const setAuthToken = (token) => {
    if (token) {
        // Apply to every request
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        localStorage.setItem(AUTH_TOKEN, token);
    } else {
        // Delete auth header
        delete axios.defaults.headers.common['Authorization'];
        localStorage.removeItem(AUTH_TOKEN);
    }
};

export default setAuthToken;