import { Typography, makeStyles } from '@material-ui/core';

const embassyAddress = makeStyles((theme) => ({
	root: {
		backgroundColor: '#228c2210',
		borderRadius: theme.shape.borderRadius,
		padding: [[theme.spacing(2), theme.spacing(4)]],
		marginTop: theme.spacing(5),
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),

		[theme.breakpoints.down('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		},

		'& h5': {
			color: theme.palette.primary.main,
			fontWeight: 600,
			marginBottom: theme.spacing(2),

			[theme.breakpoints.down('sm')]: {
				color: theme.palette.primary.main,
				fontSize: theme.spacing(2.5),
				textAlign: 'center'
			}
		}
	}
}));

export default function EmbAddress() {
  const classes = embassyAddress();

	return (
		<section className={classes.root} data-nosnippet>
			<Typography variant="h5">From the Desk of the Ambassador</Typography>
			<Typography variant="subtitle1" component="p">
				Welcome to the official website of the Embassy of Nigeria in
				Algeria. The site was created to simplify Clients' needs by offering
				enhanced services that saves time and gives satisfactory results.
				The features were deliberately made informative, user-friendly
				and interactive. The website offers everyday consular services
				and   update   Nigerians on our services. Additionally, the site keeps its
				users   abreast   of   Nigerian   Government   policies   by   regularly
				disseminating relevant information to Nigerian citizens and the
				international community regarding Government's initiatives and
				efforts   geared   towards   addressing   the   challenges   confronting
				Nigeria.   Press   release   and   feature   articles   generated   by   the
				Mission from bilateral meetings with host authorities would be
				uploaded on the website for information and reference.
			</Typography>
			<Typography variant="subtitle1" component="p">It   is   my   hope   that   intended   visitors   to   Nigeria   would   take
				advantage of this website for visa and/or any information/services
				that will facilitate a smooth journey and successful stay in Nigeria.
				Nigerian citizens resident in Algeria are encouraged to use the
				special portal set up on the site to register their names and other
				details with the Embassy.
			</Typography>
			<Typography variant="subtitle1" component="p">I guarantee that this website will help get things done seamlessly. <br />Try it now; you will be glad you did.</Typography>
			<Typography variant="subtitle1" component="p">Thank you.</Typography>
		</section>
	);
};