import { Box, makeStyles, Typography } from '@material-ui/core';
import parse from 'html-react-parser';

import Layout from '../../components/layout';
import { useSelector } from 'react-redux';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(15, 10, 3, 10),
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(0, 5)
		},

		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(0, 2)
		}
	},  
	
	imageContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		height: theme.spacing(50),
		marginTop: theme.spacing(1),
		width: '100%',

		' & img': {
			maxHeight: '100%'
		}
	},

	title: {
		marginTop: theme.spacing(2),
		textAlign: 'center'
	}
}));

const News = () => {
	const classes = useStyles();

	const { post } = useSelector(state => state.posts);

	return (
		<Layout title="News">
			<Box component="section" className={classes.root}>
				{post.imageUrl && 
					<Box className={classes.imageContainer}>
						<img src={post.imageUrl} alt={post.title} />
					</Box>
				}
				<Typography variant="h6" className={classes.title}>{post.title}</Typography>
				<small>{moment(post.createdAt).format('MMMM Do, YYYY')}</small>
				<Box component="div" className={classes.content}>
					{parse(post.body ?? '')}
				</Box>
			</Box>
		</Layout>
	);
};

export default News;