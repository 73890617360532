import { lazy, Suspense, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { Toaster } from 'react-hot-toast';
import PropTypes from 'prop-types';

import Home from './pages/home';
import ViewNigeria from './pages/viewNigeria';
import Gallery from './pages/gallery';
import News from './pages/news';

import { 
	GALLERY, 
	GOVERNMENT_AND_POLITICS,
	CONTACT,
	VIEW_NIGERIA,
	TRADE_AND_INVESTMENT,
	TRAVEL_REQUIREMENTS,
	TOURISM,
	ECONOMY,
	VISA_APPLICATION,
	LOGIN,
	FORGOT_PASSWORD,
	RESET_PASSWORD,
	DASHBOARD,
	ACCOUNT,
	CREATE_POST,
	POSTS,
	EDIT_POST,
	VIEW_POST,
	DASHBOARD_GALLERY,
	UPLOAD_IMAGE
} from './routes';

import ScrollToTop from './components/layout/ScrollToTop';
import Spinner from './components/common/Spinner';
import PrivateRoute from './components/common/PrivateRoute';
import { AUTH_TOKEN } from './utils/constants';
import { getCurrentUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

const TravellingRequirements = lazy(() => import('./pages/services/TravellingRequirements'));
const Tourism = lazy(() => import('./pages/viewNigeria/Tourism'));
const NigerianEconomy = lazy(() => import('./pages/viewNigeria/NigerianEconomy'));
const GovernmentAndPolitics = lazy(() => import('./pages/viewNigeria/GovernmentAndPolitics'));
const TradeAndInvestment = lazy(() => import('./pages/viewNigeria/TradeAndInvestment'));
const VisaApplication = lazy(() => import('./pages/services/VisaApplication'));
const Contact = lazy(() => import('./pages/contact'));

const Login = lazy(() => import('./pages/auth/Login'));
const ForgotPassword = lazy(() => import('./pages/auth/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/auth/ResetPassword'));

const Dashboard = lazy(() => import('./pages/dashboard'));
const Account = lazy(() => import('./pages/dashboard/account'));
const CreatePost = lazy(() => import('./pages/dashboard/posts/CreatePost'));
const EditPost = lazy(() => import('./pages/dashboard/posts/EditPost'));
const Posts = lazy(() => import('./pages/dashboard/posts/Posts'));

const DashboardGallery = lazy(() => import('./pages/dashboard/gallery'));
const UploadImage = lazy(() => import('./pages/dashboard/gallery/UploadImage'));

const App = ({ getCurrentUser }) => {
	const navigate = useNavigate();
	const [title, setTitle] = useState('');

	useEffect(() => {
		const token = localStorage.getItem(AUTH_TOKEN);
		if (token) {
			setAuthToken(token);
			getCurrentUser(navigate);
		}

		// eslint-disable-next-line
	}, []);

	const handleSetTitle = (title) => setTitle(title);
	
	const theme = createTheme({
		overrides: {
			MuiButton: {
				  root: {
					borderRadius: '5px',
					boxShadow: 'none !important',
					paddingBottom: '14px',
					paddingTop: '14px',
					textTransform: 'capitalize'
				  },
			},
		},
		
		palette: {
			primary: {
				// light: '#df3c3a',
				main: '#228C22',
				// dark: '#990300'
			},
	
			text: {
				// primary: '#f8f8f8',
				// secondary: '#f8f8f8'
			}
		},
	
		breakpoints: {
			values: {
				xs: 0,
				sm: 480,
				md: 768,
				lg: 1024,
				xl: 1920
			}
		},
	
		typography: {
			fontFamily: "'Kumbh Sans', sans-serif",
			fontWeightLight: 300,
			fontWeightRegular: 400,
			fontWeightMedium: 500,
			fontWeightBold: 600
		}
	});

	return (
		<ThemeProvider theme={theme}>
			<Suspense fallback={<Spinner />}>
				<Toaster />
				<ScrollToTop>
					<Routes>
						<Route path="/" exact element={<Home />} />
						<Route path={VIEW_NIGERIA} exact element={<ViewNigeria />} />
						<Route path={GALLERY} exact element={<Gallery />} />
						<Route path={VIEW_POST} exact element={<News />} />
						<Route path={CONTACT} exact element={<Contact />} />
						<Route path={TRAVEL_REQUIREMENTS} exact element={<TravellingRequirements />} />
						<Route path={TOURISM} exact element={<Tourism />} />
						<Route path={ECONOMY} exact element={<NigerianEconomy />} />
						<Route path={GOVERNMENT_AND_POLITICS} exact element={<GovernmentAndPolitics />} />
						<Route path={TRADE_AND_INVESTMENT} exact element={<TradeAndInvestment />} />
						<Route path={VISA_APPLICATION} exact element={<VisaApplication />} />
						<Route path={VISA_APPLICATION} exact element={<VisaApplication />} />
						<Route path={LOGIN} exact element={<Login />} />
						<Route path={FORGOT_PASSWORD} exact element={<ForgotPassword />} />
						<Route path={RESET_PASSWORD} exact element={<ResetPassword />} />
						<Route path={DASHBOARD} element={<PrivateRoute><Dashboard title={title} /></PrivateRoute>}>
							<Route index path={ACCOUNT} element={<Account handleSetTitle={handleSetTitle} />} />
							<Route index path={CREATE_POST} element={<CreatePost handleSetTitle={handleSetTitle} />} />
							<Route index path={EDIT_POST} element={<EditPost handleSetTitle={handleSetTitle} />} />
							<Route index path={POSTS} element={<Posts handleSetTitle={handleSetTitle} />} />
							<Route index path={DASHBOARD_GALLERY} element={<DashboardGallery handleSetTitle={handleSetTitle} />} />
							<Route index path={UPLOAD_IMAGE} element={<UploadImage handleSetTitle={handleSetTitle} />} />
						</Route>
					</Routes>
				</ScrollToTop>
			</Suspense>
		</ThemeProvider>
	);
}


App.propTypes = {
	getCurrentUser: PropTypes.func.isRequired
};

export default connect(undefined, { getCurrentUser })(App);
