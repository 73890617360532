import axios from 'axios';
import { batch } from 'react-redux';
import { toast } from 'react-hot-toast';
import handleError from '../utils/handleError';

import { REMOVE_POST, SET_POSTS, EDITED_POST, SET_POST_MSG } from './types';

const API = '/posts';

export const createPost = (post) => async (dispatch) => {
    try {
        const res = await axios.post(API, post);
        return dispatch({
            type: SET_POST_MSG,
            payload: res.data.msg
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const editPost = (post, postId) => async (dispatch) => {
    try {
        const res = await axios.put(`${API}/${postId}`, post);
        return batch(() => {
            dispatch({
                type: EDITED_POST,
                payload: res.data.data
            });
            dispatch({
                type: SET_POST_MSG,
                payload: res.data.msg
            });
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const removePostImage = (postId) => async (dispatch) => {
    try {
        const res = await axios.delete(`${API}/removeImage/${postId}`);
        return batch(() => {
            dispatch({
                type: EDITED_POST,
                payload: res.data.data
            });
            toast.success(res.data.msg);
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const getPosts = ({ limit, page }) => async (dispatch) => {
    try {
        const res = await axios.get(`${API}?page=${page}&limit=${limit}`);
        const { data, pagination } = res.data;
        return dispatch({
            type: SET_POSTS,
            payload: {
                posts: data,
                pagination
            }
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const deletePost = (_id) => async (dispatch) => {
    try {
        const res = await axios.delete(`${API}/${_id}`);
        return batch(() => {
            dispatch({
                type: REMOVE_POST,
                payload: _id
            });
            dispatch({
                type: SET_POST_MSG,
                payload: res.data.msg
            });
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};