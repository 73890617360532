import{ useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = (props) => {
    const location = useLocation();
    const [currentPath, setCurrentPath] = useState('');

    useEffect(() => {
        setCurrentPath(location.pathname);
        if (currentPath !== location.pathname) {
            window.scrollTo(0, 0);
        }
    }, [currentPath, location.pathname]);

    return props.children;
}

export default ScrollToTop;