import { Link, useLocation } from 'react-router-dom';
import { Link as AnimatedLink } from 'react-scroll';
import { makeStyles } from '@material-ui/core/styles';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Drawer,
    List,
    Typography
} from '@material-ui/core';
import { ChevronUp } from 'mdi-material-ui';
import clsx from 'clsx';

import { COLORS } from '../../utils/constants';
import { CONTACT, GALLERY, NEWS, SERVICES, VIEW_NIGERIA, NIGERIA_NEWSPAPERS, nigerianNewspapers, viewNigeriaLinks, POSTS } from '../../routes';

import ListItemLink from './ListItemLink';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    drawer: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(5),
        width: '60%',
    },

    link: {
        alignSelf: 'center',
        color: 'inherit',
        cursor: 'pointer',
        textDecoration: 'none'
    },

    activeLink: {
        color: theme.palette.primary.main,
        fontWeight: 600
    },

    externalLink: {
        display: 'block',
        textAlign: 'left !important',
        textDecoration: 'none',
        // marginLeft: theme.spacing(1),
        padding: theme.spacing(1),
        width: '100%'
    },

    drawerLogo: {
        marginBottom: theme.spacing(5),
        width: '10%'
    },

    menuButton: {
        color: theme.palette.primary.main
    },

    accordionDetails: {
        display: 'flex',
        flexDirection: 'column'
    },

    copyright: {
        color: COLORS.lightGray,
        display: 'inline-block',
        position: 'absolute',
        bottom: 10,
        textAlign: 'center',
        width: '100%'
    }
}));

const MobileNav = ({ toggleDrawer, drawerOpen }) => {
    const classes = useStyles();
    const location = useLocation();

    const { isAuthenticated } = useSelector(state => state.auth);

    const links = [
		{ url: '/', text: 'Home'},
		{ url: SERVICES, text: 'Consular Services' },
		{ url: VIEW_NIGERIA, text: 'View Nigeria' },
		{ url: NIGERIA_NEWSPAPERS, text: 'Nigerian Newspapers' },
		{ url: NEWS, text: 'News/Updates' },
		{ url: GALLERY, text: 'Gallery' },
		{ url: CONTACT, text: 'Contact' }
	];

    const handleOpenLink = (url) => {
        toggleDrawer();
        window.open(url, '_blank');
    };

    return (
        <section>
            <Drawer PaperProps={{ className: classes.drawer }} anchor="left" open={drawerOpen} onClose={toggleDrawer}>
                {/* <Link to="/" className={classes.link}>
                    <img src={logo} alt="FX Blooms Logo" className={classes.drawerLogo} />
                </Link> */}
                <List>
                  {isAuthenticated && 
                        <ListItemLink button divider>
                            <Link 
                                to={POSTS} 
                                className={classes.link}
                                onClick={toggleDrawer}
                            >
                                Dashboard
                            </Link>
                        </ListItemLink> 
                    }
                    {links.map(({text, url}, index) => {
                        if (location.pathname === '/') {
                            if (url === CONTACT) {
                                return (
                                    <ListItemLink button divider key={index}>
                                        {/* <ListItemIcon>
                                            {icon}
                                        </ListItemIcon> */}
                                        <Link 
                                            to={url} 
                                            activeClass={classes.activeLink} 
                                            className={classes.link}
                                            onClick={toggleDrawer}
                                        >
                                            {text}
                                        </Link>
                                    </ListItemLink> 
                                );
                            }
                            if (url === VIEW_NIGERIA) {
                                return (
                                    <Accordion key={index}>
                                        <AccordionSummary
                                            expandIcon={<ChevronUp />}
                                            aria-controls="panel1a-content"
                                            id="view-nigeria-acordion"
                                        >
                                            <Typography>View Nigeria</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className={classes.accordionDetails}>
                                            {viewNigeriaLinks.map((link, index) => (
                                                <ListItemLink key={index} button divider onClick={toggleDrawer}>
                                                    <Link 
                                                        to={link.url}
                                                        className={classes.link}
                                                    >
                                                        {link.text}
                                                    </Link>
                                                </ListItemLink>
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            }
                            if (url === NIGERIA_NEWSPAPERS) {
                                return (
                                    <Accordion key={index}>
                                        <AccordionSummary
                                            expandIcon={<ChevronUp />}
                                            aria-controls="panel1a-content"
                                            id="view-nigeria-acordion"
                                        >
                                            <Typography>Nigerian Newspapers</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className={classes.accordionDetails}>
                                            {nigerianNewspapers.map((link, index) => (
                                                <ListItemLink key={index} button divider onClick={() => handleOpenLink(link.url)}>
                                                    <a 
                                                        key={index}
                                                        href={link.url}
                                                        className={clsx(classes.link, classes.externalLink)}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {link.text}
                                                    </a>
                                                </ListItemLink>
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            }
                            return (
                                <ListItemLink button divider to="#!" key={index}>
                                    {/* <ListItemIcon>
                                        {icon}
                                    </ListItemIcon> */}
                                    <AnimatedLink 
                                        to={url} 
                                        activeClass={classes.activeLink} 
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={500}
                                        className={classes.link}
                                        onClick={toggleDrawer}
                                    >
                                        {text}
                                    </AnimatedLink>
                                </ListItemLink>    
                            );
                        }
                        if (url === VIEW_NIGERIA) {
                            return (
                                <Accordion key={index}>
                                    <AccordionSummary
                                        expandIcon={<ChevronUp />}
                                        aria-controls="panel1a-content"
                                        id="view-nigeria-acordion"
                                    >
                                        <Typography>View Nigeria</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.accordionDetails}>
                                        {viewNigeriaLinks.map((link, index) => (
                                            <ListItemLink key={index} button divider onClick={toggleDrawer}>
                                                <Link 
                                                    to={link.url}
                                                    className={classes.link}
                                                >
                                                    {link.text}
                                                </Link>
                                            </ListItemLink>
                                        ))}
                                    </AccordionDetails>
                                </Accordion>
                            );
                        }
                        if (url === NIGERIA_NEWSPAPERS) {
                            return (
                                <Accordion key={index}>
                                    <AccordionSummary
                                        expandIcon={<ChevronUp />}
                                        aria-controls="panel1a-content"
                                        id="view-nigeria-acordion"
                                    >
                                        <Typography>Nigerian Newspapers</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.accordionDetails}>
                                        {nigerianNewspapers.map((link, index) => (
                                            <ListItemLink key={index} button divider onClick={() => handleOpenLink(link.url)}>
                                                <a 
                                                    key={index}
                                                    href={link.url}
                                                    className={clsx(classes.link, classes.externalLink)}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {link.text}
                                                </a>
                                            </ListItemLink>
                                        ))}
                                    </AccordionDetails>
                                </Accordion>
                            );
                        }
                        return (
                            <ListItemLink button divider key={index}>
                                {/* <ListItemIcon>
                                    {icon}
                                </ListItemIcon> */}
                                <Link 
                                    to={url} 
                                    activeClass={classes.activeLink} 
                                    className={classes.link}
                                    onClick={toggleDrawer}
                                >
                                    {text}
                                </Link>
                            </ListItemLink>    
                        );
                    })}
                </List>
                <Typography variant="subtitle2" className={classes.copyright}>Nigerian Embassy Algiers, Algeria. &copy; {new Date().getFullYear()} All rights reserved.</Typography>
            </Drawer>
        </section>
    );
}

export default MobileNav;