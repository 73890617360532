export const VIEW_NIGERIA = '/viewNigeria';
export const NIGERIA_NEWSPAPERS = '/nigerianNewsPapers';
export const HOLIDAYS = '/holidays';
export const SERVICES = '/consulerServices';
export const CONTACT = '/contact';
export const NEWS = '/newsAndUpdates';
export const GALLERY = '/gallery';
export const PASSPORT_APPLICATION = '/passportApplication';
export const TRAVEL_REQUIREMENTS = '/travelRequirements';
export const TOURISM = '/tourism';
export const ECONOMY = '/nigerianEconomy';
export const GOVERNMENT_AND_POLITICS = '/governmentAndPolitics';
export const TRADE_AND_INVESTMENT = '/tradeAndInvestment';
export const VISA_APPLICATION = '/visaApplication';
export const VIEW_POST = `${NEWS}/article`;

const AUTH = '/auth';
export const LOGIN = `${AUTH}/login`;
export const FORGOT_PASSWORD = `${AUTH}/forgotPassword`;
export const RESET_PASSWORD = `${AUTH}/resetPassword`;

export const DASHBOARD = '/dashboard';
export const ACCOUNT = `${DASHBOARD}/account`;

export const POSTS = `${DASHBOARD}/posts`;
export const CREATE_POST = `${DASHBOARD}/createPost`;
export const EDIT_POST = `${DASHBOARD}/editPost`;
export const DASHBOARD_GALLERY = `${DASHBOARD}/gallery`;
export const UPLOAD_IMAGE = `${DASHBOARD_GALLERY}/upload`;

export const viewNigeriaLinks = [
    { url: VIEW_NIGERIA, text: 'About Nigeria'},
    { url: TOURISM, text: 'Tourism' },
    { url: TRADE_AND_INVESTMENT, text: 'Trade and Investment' },
    { url: ECONOMY, text: 'Nigerian Economy' },
    { url: GOVERNMENT_AND_POLITICS, text: 'Government & Politics' }
];

export const nigerianNewspapers = [
    { url: 'https://punchng.com/', text: 'Punch'},
    { url: 'https://www.newswatchngr.com/', text: 'Newswatch' },
    { url: 'https://guardian.ng/', text: 'Guardian' },
    { url: 'https://www.vanguardngr.com/', text: 'Vanguard' },
    { url: 'https://www.sunnewsonline.com/', text: 'The Sun' }
];

export const PRIVATE_LINKS = [
    { url: CREATE_POST, text: 'Create Post' },
    { url: POSTS, text: 'Posts' },
    { url: DASHBOARD_GALLERY, text: 'Gallery' },
    { url: ACCOUNT, text: 'Account' }
];