import { CREATE_ATTACHMENT, DELETE_ATTACHMENT, SET_ATTACHMENTS, SET_ATTACHMENT_MSG } from '../actions/types';

const initialState = {
    attachments: [],
    msg: null
};

const attachmentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_ATTACHMENT:
            return {
                ...state,
                attachments: [action.payload, ...state.attachments]
            };

        case DELETE_ATTACHMENT:
            return {
                ...state,
                attachments: state.attachments.filter(attachment => attachment._id !== action.payload)
            };

        case SET_ATTACHMENTS:
            return {
                ...state,
                attachments: action.payload
            };

        case SET_ATTACHMENT_MSG:
            return {
                ...state,
                msg: action.payload
            };
        default:
            return state;
    }
};

export default attachmentsReducer;