import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Layout from '../../components/layout';

import { getPosts } from '../../actions/posts';

import HeroSection from './HeroSection';
import Services from './Services';
import EmbassyAddress from './EmbassyAddress';
import Gallery from './Gallery';
import NewsUpdates from './NewsUpdates';
import ViewNigeria from './ViewNigeria';
import Team from './Team';

const Index = ({ getPosts }) => {
    useEffect(() => {
        getPosts({ page: 1, limit: 25 });

        // eslint-disable-next-line
    }, []);

    return (
        <Layout title="Embassy of Nigeria in Algeria">
            <HeroSection/>
            <Services/>
            <EmbassyAddress/>
            <Gallery/>
            <NewsUpdates/>
            <Team />
            <ViewNigeria/>
        </Layout>
    );
};

Index.propTypes = {
    getPosts: PropTypes.func.isRequired
};

export default connect(undefined, { getPosts })(Index);