import { GET_ERRORS } from '../actions/types';
import { NETWORK_ERROR } from './constants';

const handleError =  (err, dispatch) => {
    console.error(err);
    console.log(err?.response?.errors);
    
    if (err?.message === NETWORK_ERROR) {
        return dispatch({
            type: GET_ERRORS,
            payload: { msg: NETWORK_ERROR }
        });     
    }
    
    console.log(err.response);
    const { errors } = err?.response?.data;
    return dispatch({
        type: GET_ERRORS,
        payload: { ...errors }
    });
};

export default handleError;