import axios from 'axios';
import { POSTS } from '../routes';
import handleError from '../utils/handleError';
import setAuthToken from '../utils/setAuthToken';

import { AUTH_TOKEN } from '../utils/constants';
import { RESET_STORE, SET_AUTH_MSG, SET_CURRENT_USER } from './types';
import { batch } from 'react-redux';

const API = '/auth';

export const getCurrentUser = (navigate) => async (dispatch) => {
    try {
        const res = await axios.get(API);
        const user = res.data.data;
        dispatch({
            type: SET_CURRENT_USER,
            payload: user
        });
        return navigate(POSTS);
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const login = (data, navigate) => async (dispatch) => {
    try {
        const res = await axios.post(`${API}/login`, data);
        const user = res.data.data.user;
        setAuthToken(res.data.data.token);
        dispatch({
            type: SET_CURRENT_USER,
            payload: user
        });
        return navigate(POSTS);
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const updateProfile = (data) => async (dispatch) => {
    try {
        const res = await axios.put(API, data);
        return batch(() => {
            dispatch({
                type: SET_CURRENT_USER,
                payload: res.data.data
            });
            dispatch({
                type: SET_AUTH_MSG,
                payload: res.data.msg
            });
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const updatePassword = (data) => async (dispatch) => {
    try {
        await axios.put(`${API}/changePassword`, data);
        dispatch({
            type: SET_AUTH_MSG,
            payload: 'Password changed successfully'
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const forgotPassword = (email) => async (dispatch) => {
    try {
        const res = await axios.post(`${API}/forgotPassword`, { email });
        return dispatch({
            type: SET_AUTH_MSG,
            payload: res.data.data.msg
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const resetPassword = (data, token) => async (dispatch) => {
    try {
        await axios.put(`${API}/resetPassword/${token}`, data);
        return dispatch({
            type: SET_AUTH_MSG,
            payload: 'Password reset successfully. Kindly login with your new password'
        });
    } catch (err) {
        return handleError(err, dispatch);
    }
};

export const logout = (navigate) => async (dispatch) => {
    try {
        dispatch({ type: RESET_STORE });
        localStorage.removeItem(AUTH_TOKEN);
        return navigate('/');
    } catch (err) {
        return handleError(err, dispatch);
    }
};