import { combineReducers } from 'redux';

import { RESET_STORE } from '../actions/types';

import authReducer from './auth';
import galleryReducer from './gallery';
import postsReducer from './posts';
import errorsReducer from './errors';
import attachmentsReducer from './attachment';

const appReducer = combineReducers({
    attachments: attachmentsReducer,
    auth: authReducer,
    gallery: galleryReducer,
    posts: postsReducer,
    errors: errorsReducer
});

const rootReducer = (state, action) => {
    if (action.type === RESET_STORE) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;