import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Button, 
  Container, 
  Grid,
  Typography, 
  CardActionArea, 
  CardActions, 
  Card, 
  CardMedia, 
  CardContent 
} from '@material-ui/core';
import TextClamp from 'react-string-clamp';
import moment from 'moment';
import img from '../../assets/img/news-logo.jpeg';

import { SET_POST } from '../../actions/types';

import { NEWS, VIEW_POST } from '../../routes';


const useStyles = makeStyles((theme) => ({
    root: {
		display: 'flex',
		flexDirection: 'column',

		'& h4': {
			textAlign: 'center'
		}
    },

    media: {
      height: 140,
    },

    btn: {
      display: 'flex',
      justifyContent: 'center',
      top: '40%',
      left: '35%',
    },

	btnredirect: {
		marginTop: theme.spacing(4),
		alignSelf: 'center',

		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
}));

const NewsUpdates = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { posts } = useSelector(state => state.posts);

  const handleViewPost = (post) => {
	dispatch({
		type: SET_POST,
		payload: post
	});
	return navigate(VIEW_POST)
  };
  
  return (
    <Container className={classes.root} id={NEWS}>
        <Typography variant="h4">News and Updates</Typography><br/>
        <Grid container spacing={3}>
			{posts.map((post) => (
				<Grid item xs={12} sm={6} md={4} key={post._id}>
					<Card className={classes.root}>
						<CardActionArea>
							<CardMedia
								className={classes.media}
								image={post.imageUrl ?? img}
								title=""
							/>
							<CardContent>
								<Typography gutterBottom variant="h6">
								<TextClamp
									text={post.title}
									lines={3}
								/>
								</Typography>
								<Typography>{moment(post.createdAt).format('MMMM Do, YYYY')}</Typography><br/>
							</CardContent>
						</CardActionArea>
						<CardActions>
							<Button to={NEWS} onClick={() => handleViewPost(post)} size="small" variant="outlined" color="primary" className={classes.btn}>
								Read more
							</Button>
						</CardActions>
					</Card>
				</Grid>
			))}
        </Grid>
    </Container>
  );
}

export default NewsUpdates;