import { SET_AUTH_MSG, SET_CURRENT_USER } from '../actions/types';

const initialState = {
    isAuthenticated: false,
    user: {},
    msg: null
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                user: action.payload,
                isAuthenticated: true
            };

        case SET_AUTH_MSG:
            return {
                ...state,
                msg: action.payload
            };
        default:
            return state;
    }
};

export default authReducer;