import { makeStyles, Typography } from '@material-ui/core';

import { COLORS } from '../../utils/constants';
import nigerianFlag from '../../assets/img/nigerian-flag.webp';
import algerianFlag from '../../assets/img/algeria-flag.webp';

const mainSection = makeStyles((theme) => ({
    root: {
      	backgroundColor: theme.palette.primary.main,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: theme.spacing(1, 5)
    },

    text: {
		color: COLORS.offWhite,
		fontWeight: 300,
		textAlign: 'center'
    },

	flag: {
		width: theme.spacing(10),

		[theme.breakpoints.down('sm')]: {
			width: theme.spacing(3)
		}
	}
}));

const Footer = () => {
  	const classes = mainSection();

	return (
		<footer className={classes.root}>
			<img src={nigerianFlag} alt="Nigerian FLag" className={classes.flag} />
			<Typography variant="subtitle2" component="p" className={classes.text}> Nigerian Embassy Algiers, Algeria. &copy; {new Date().getFullYear()} All rights reserved.</Typography>
			<img src={algerianFlag} alt="Algerian FLag" className={classes.flag} />
		</footer>    
	);
}

export default Footer;