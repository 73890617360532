import { EDITED_POST, REMOVE_POST, SET_POST, SET_POSTS, SET_POST_MSG } from '../actions/types';

const initialState = {
    pagination: {},
    posts: [],
    post: {},
    msg: null
};

const postsReducer = (state = initialState, action) => {
    let posts = [];
    let postIndex = null;

    switch (action.type) {
        case SET_POSTS:
            return {
                ...state,
                posts: action.payload.posts,
                pagination: action.payload.pagination
            };

        case SET_POST:
            return {
                ...state,
                post: action.payload
            };

        case REMOVE_POST:
            return {
                ...state,
                posts: state.posts.filter(post => post._id !== action.payload)
            };

        case EDITED_POST:
            posts = state.posts;
            postIndex = posts.findIndex(post => post._id === action.payload._id);
            posts.splice(postIndex, 1, action.payload);

            return {
                ...state,
                posts
            };

        case SET_POST_MSG:
            return {
                ...state,
                msg: action.payload
            };
        default:
            return state;
    }
};

export default postsReducer;