export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_AUTH_MSG = 'SET_AUTH_MSG';
export const SET_POST_MSG = 'SET_POST_MSG';
export const SET_POST = 'SET_POST';
export const EDITED_POST = 'EDITED_POST';
export const SET_POSTS = 'SET_POSTS';
export const REMOVE_POST = 'REMOVE_POST';
export const DELETED_IMAGE = 'DELETED_IMAGE';
export const SET_GALLERY_MSG = 'SET_GALLERY_MSG';
export const SET_ATTACHMENTS = 'SET_ATTACHMENTS';
export const CREATE_ATTACHMENT = 'CREATE_ATTACHMENT';
export const DELETE_ATTACHMENT = 'DELETE_ATTACHMENT';
export const SET_ATTACHMENT_MSG = 'SET_ATTACHMENT_MSG';
export const GET_ERRORS = 'GET_ERRORS';
export const RESET_STORE = 'RESET_STORE';