import { DELETED_IMAGE, SET_GALLERY_MSG } from '../actions/types';

const initialState = {
    deletedImage: false,
    imageUrl: null,
    msg: null
};

const errorsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_GALLERY_MSG:
            return {
                ...state,
                msg: action.payload
            };

        case DELETED_IMAGE:
            return { 
                imageUrl: action.payload ? action.payload : null,
                deletedImage: !state.deletedImage,
                ...action.payload 
            };

        default:
        return state;
    }
};

export default errorsReducer;